<template>

  <div class="flex flex-col w-full">

    <div class="flex flex-col items-center">

      <div class="text-4xl text-pt-gray uppercase text-center font-bold mt-8 w-full">
        Our Works
      </div>

      <div class="max-w-screen-lg">
        <p class="my-4">
          With the massive variety in terms of materials and sizes available for us to print with, the cost of printing varies per job. However at Printotype we try to help make this process more transparent by establishing these basic ground rules:
          <ul class="list-disc ml-4">
            <li>
              £12.00 Minimum Order Value.
            </li>
            <li>
              £3.00 P&P if in the UK – Extra­large models or large volume orders will incur higher charges.
            </li>
            <li>
              Delivery to Ireland, will be an Extra £6.00 per order.International Shipping available on request.
            </li>
            <li>
              If there are problems with your Gcode on your STL File or OBJ File and it is not able to successfully 3D Print as it should I will let you know and make a small charge to Repair it, or alternatively I can put your project on hold for 24hrs whilst you work on it yourself & resend me the updated file.
            </li>
            <li>
              If I have 3D Printed over 50% of your Model and comes up with errors I will charge 50% of the unit cost to reprint.
            </li>
            <li>
              If a design is inherently fragile, we may consider printing it with Supports. This may marginally increase the cost as the weight will go up, but improves chances of a successful print. Printotype Ltd will ALWAYS inform you if we feel a project requires Supports. A Support cleaning service is also available starting from £5.00.
            </li>
          </ul>
        </p>

        <p class="my-4">
          Printotype Ltd operates under the Data Protection Act 1998, so all Files & Drawings are treated as confidential. Unless specified by the client prior to commencement of work, all project data will be destroyed within 30 days of project completion.
        </p>
      </div>

      <div class="text-4xl text-pt-gray uppercase text-center font-bold mt-8 w-full">
        Example Case Studies
      </div>

      <div class="flex flex-col justify-center text-center">
        <div class="mt-4 uppercase text-xl text-pt-gray font-bold text-center mb-2">
          3x Keyrings
        </div>

        <div class="flex gap-5 justify-center">
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/keychain_1.jpg"
            alt=""
          >
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/keychain_2.jpg"
            alt=""
          >
        </div>

        <div class="max-w-screen-lg mt-2 text-left">
          This was a set of keyrings designed for a client looking to help promote his business and was looking for a unique angle to do so.
          <br />Print cost ­£15.
        </div>
      </div>


      <div class="flex flex-col justify-center text-center">
        <div class="mt-4 uppercase text-xl text-pt-gray font-bold text-center mb-2">
          House Layout Model
        </div>

        <div class="flex gap-5 justify-center">
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/house_1.jpg"
            alt=""
          >
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/house_2.jpg"
            alt=""
          >
        </div>

        <div class="max-w-screen-lg mt-2 text-left">
          This model was designed using 2d Floor Plans provided by client. We converted this into 3d data and then printed the design to produce an eye catching, accurate depiction of the property, at a fraction of the cost compared to traditional wood and card architectural models.
          <br />CAD Design Cost: ­£63
          <br />Print Cost: ­£130
        </div>
      </div>


      <div class="flex flex-col justify-center text-center">
        <div class="mt-4 uppercase text-xl text-pt-gray font-bold text-center mb-2">
          Vase
        </div>

        <div class="flex gap-5 justify-center">
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/vase_1.jpg"
            alt=""
          >
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/vase_2.jpg"
            alt=""
          >
        </div>

        <div class="max-w-screen-lg mt-2 text-left">
          This was an eye catching model similar to a style they had seen on travels abroad. by creating this unique shape, we provided the client with an interesting conversation piece and unique memento.
          <br />Print Cost: ­£48
        </div>
      </div>


      <div class="flex flex-col justify-center text-center">
        <div class="mt-4 uppercase text-xl text-pt-gray font-bold text-center mb-2">
          Halloween Mask
        </div>

        <div class="flex gap-5 justify-center">
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/mask_1.jpg"
            alt=""
          >
          <img
            class="max-w-xs rounded-lg"
            src="https://bfd-main-space-amsterdam.ams3.digitaloceanspaces.com/printotype.co.uk-live/mask_2.jpg"
            alt=""
          >
        </div>

        <div class="max-w-screen-lg mt-2 text-left mb-24">
          A client came to us with an urgent request for a model for a themed halloween party. against the odds we were able to produce it in time as well as some additional finishing* to make their piece truly look "the part"!
          <br />Print Cost:­ £18
          <br />*Additional Painting & Finishing charge not included
        </div>
      </div>

    </div>

  </div>

</template>

<script>

export default {
  mounted() {

  },
  data() {
    return {

    }
  },
  components: {

  },
  methods: {

  }
}
</script>